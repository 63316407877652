import React, { useState, useEffect } from "react";
import SplashImage from "./Images/image1.gif";
import StreetImage from "./Images/image2.png";
import BackgroundImage from "./Images/image3.png";
import UnderConstruction from "./Images/image4.png";
import { FaFacebookF, FaInstagram, FaTelegram, FaTwitter } from "react-icons/fa"; // Install react-icons
import "./styles.css";

export default function App() {
  const [isSetup, setIsSetUp] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsSetUp(true);
    }, 1000);
  }, []);

  return (
    <div className="App">
      <img src={StreetImage} alt="Street Header" />
      <div className="socials-block">
        <div className="socials-background">
        <div className="socials-block-content">
          <div className="social-icons">
            <a href="https://x.com/goldloxe" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <FaTwitter />
            </a>
            <a href="https://www.instagram.com/goldloxe" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <FaInstagram />
            </a>
            <a href="https://t.me/+2dbIAiWbL3liNTAx" target="_blank" rel="noopener noreferrer" aria-label="Telegram">
              <FaTelegram/>
            </a>
          </div>
        </div>
        </div>
      </div>
      <img src={SplashImage} alt="Splash" />
      <img className="under-construction"src={UnderConstruction} alt="Under Construction" />
    </div>
  );
}